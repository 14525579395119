<template>
  <div class="m-pg-sub" v-loading="loading" element-loading-text="loading...">
    <el-button
      class="back-btn"
      type="primary"
      plain
      icon="el-icon-arrow-left"
      round
      @click="onBack">
      返回
    </el-button>
    <iframe @load="iframeLoaded" :src="url"></iframe>
  </div>
</template>

<script>
export default {
  name: "AncientMap",
  data() {
    return {
      loading: false,
      url: ''
    }
  },
  methods: {
    iframeLoaded() {
      this.loading = false
    },
    onBack: function() {
      this.$router.replace('/visualized')
    }
  },
  mounted() {
    this.loading = true
    this.url = decodeURIComponent(this.$route.query.url)
  }
}
</script>

<style scoped>
.m-pg-sub {
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  background: #f9f9f9;
  bottom: 0;
  z-index: 1;
}
.m-pg-sub iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.back-btn {
  position: absolute;
  top: 12px;
  left: 80px;
  z-index: 99;
}
</style>
